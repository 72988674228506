import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div>
      <p>Privacy Policy for Solaris Health Partners
Effective Date: [Insert Date]

At Solaris Health Partners, accessible from solarishealthpartners.org, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Solaris Health Partners and how we use it.

Information We Collect
When you visit our website, we may collect the following types of information:

Personal Information: We may collect personal details like your name, email address, and contact information when you voluntarily submit them.
Usage Data: We collect data on how you interact with our website, including IP address, browser type, the pages visited, and the time spent on those pages.
How We Use Your Information
We use the information we collect in the following ways:

To provide, operate, and maintain our website.
To improve, personalize, and expand our website.
To communicate with you, either directly or through one of our partners, including for customer service or to send you updates.
Cookies and Web Beacons
Like most websites, Solaris Health Partners uses 'cookies'. These cookies are used to store information, including visitor preferences and the pages on the website that the visitor accessed or visited. The information is used to optimize the user experience by customizing our web page content based on visitors' browser type and other information.

Data Security
We take reasonable steps to ensure that the information you provide is secure. However, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge that there are security and privacy limitations of the Internet which are beyond our control.

Third-Party Privacy Policies
Solaris Health Partners's Privacy Policy does not apply to other websites or third-party services. We advise you to consult the respective Privacy Policies of these third-party services for more detailed information.

Changes to This Privacy Policy
We may update our Privacy Policy from time to time. Any changes will be posted on this page with an updated revision date.

Contact Us
If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at privacy@solarishealthpartners.org.

</p>
      <head>
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6647938117361321"
     crossorigin="anonymous"></script>
      </head>
      <h1>Privacy Policy</h1>
      <p>privacy innit</p>
    </div>
  );
};

export default PrivacyPolicy;
