
import './App.css';
import LandingPage from './LandingPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivacyPolicy from './PrivacyPolicy';

function App() {
  return (
    <div className="App">
      <head className="App-header">
        
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6647938117361321"
     crossorigin="anonymous"></script>
     </head> 
     <LandingPage />
     <Router>
      <Routes>
      
        
      <Route path="/" element={<LandingPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
       
      
      </Routes>
      </Router>
    </div>
  );
}

export default App;
