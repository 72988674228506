import React from "react";


const LandingPage = () => {
     return (
        <div>
        <h1>Welcome</h1>
        <head>
         <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6647938117361321"
     crossorigin="anonymous"></script>
        </head>
        </div>
     )
    

};

export default LandingPage;